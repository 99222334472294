import { Box, createStyles, ScrollArea} from '@mantine/core';
import React, {FC, useEffect, useMemo, useRef} from 'react';
import styles from './SearchableSelectWithCheckboxes.module.scss';
import {SelectItem, SelectItemProps} from "./components";
import {useDisclosure} from "@mantine/hooks";
import {SelectData, SelectedData} from "./interface";
import {Search} from "../Search/Search";
import {useVirtualizer} from "@tanstack/react-virtual";

const useStyles = createStyles((theme) => ({
  dropdown__scrollableArea: {
    padding: '5px',
    paddingRight: '8px',
    boxSizing: 'border-box',
  },
  notFound: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '67px',
    padding: '4px 10px',
    color: theme.colors.greyLight[2],
  },
  inputRightSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '72px',
  },
  input: {
    paddingRight: '72px',
  },

  select__iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    marginRight: '14px',
  },
  select: {
    position: 'relative',
  },
  dropdown: {
    position: 'absolute',
    borderRadius: '4px',
    border: `1px solid ${theme.colors.greyDark[1]}`,
    backgroundColor: theme.colors.greyDark[5],
    zIndex: 300,
    width: '100%',
  },
}));
const compareArraysByValues = (arr1: SelectData[], arr2: SelectedData[]) => {
  let isSame = true;
  if (arr1.length !== arr2.length) return false;
  const set2 = new Set(...arr2);
  for (let i = 0; i < arr1.length; i++) {
    if (!set2.has(arr1[i].value)) {
      isSame = false;
      break;
    }
  }
  return isSame;
};

interface TargetingSelectProps {
  selectData: SelectData[];
  onChange: (data: { value: SelectedData, checked: boolean }) => void;
  search?: string;
  dropdownHeight?: number;
  onSearch?: (value: string) => void;
  withCheckboxes?: boolean;
  dropdownBottom?: React.ReactNode;
  placeholder?: string;
  dropdownState: boolean;
  dropdownHandler: {
    readonly open: () => void;
    readonly close: () => void;
    readonly toggle: () => void;
  };
  isAutoFocus?: boolean;
}

interface VirtualSelectItemProps extends SelectItemProps {
  size: number;
  start: number;
}

const VirtualSelectItem: React.FC<VirtualSelectItemProps> = React.memo(({start, size, ...selectItemProps}) => {
  return (
    <Box

      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: `${size}px`,
        transform: `translateY(${start}px)`,
        display: 'flex',
      }}
    >
      <SelectItem
        {...selectItemProps}
      />
    </Box>
  )
})

export const SelectWithCheckboxes: FC<TargetingSelectProps> = ({
                                                                 selectData = [],
                                                                 onChange,
                                                                 search,
                                                                 dropdownHeight: outerDropdownHeight,
                                                                 onSearch,
                                                                 withCheckboxes = true,
                                                                 dropdownBottom,
                                                                 placeholder = 'Search',
                                                                 isAutoFocus,
                                                                 dropdownState,
                                                                 dropdownHandler
                                                               }) => {
  const dropdownRef = React.useRef<HTMLDivElement>(null);
  useEffect(() => {
    const closeDropdown = (e: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target as Node)) {
        dropdownHandler.close();
      }
    };
    window.addEventListener('mousedown', closeDropdown);
    return () => {
      window.removeEventListener('mousedown', closeDropdown);
    };
  }, []);
  const searchWrapperRef = useRef<HTMLDivElement>(null)

  const spaceToBottom = useMemo(() => {
    if (!searchWrapperRef.current) return 0;
    return window.innerHeight - searchWrapperRef.current.getBoundingClientRect().bottom;
  }, [searchWrapperRef.current])

  const dropdownHeight = useMemo(() => {
    const dropdownBottomHeight = dropdownBottom ? 69 : 0;

    if (outerDropdownHeight) return outerDropdownHeight - 4;

    // logic for small screens
    if(spaceToBottom < 340 + dropdownBottomHeight) {
      return spaceToBottom - 35 - dropdownBottomHeight;
    }

    // if no outerDropdownHeight provided. Show max 5 items
    if (!outerDropdownHeight) {
      if (selectData.length > 5) return 340;
      return selectData.length * 67 + 10;
    }
  }, [outerDropdownHeight, selectData.length, spaceToBottom, dropdownBottom])
  const scrollAreaRef = React.useRef<HTMLDivElement>(null);
  const {classes} = useStyles();
  const renderData = useMemo(() => {
    if (!search) return selectData;
    return selectData.filter((item) => item.label.toLowerCase().includes(search.toLowerCase()));
  }, [selectData, search]);

  const rowVirtualizer = useVirtualizer({
    count: renderData.length,
    getScrollElement: () => scrollAreaRef.current,
    estimateSize: () => 67,
    overscan: 8,
  });

  useEffect(() => {
    document.documentElement.style.setProperty("--scrollable-select-total-size", `${rowVirtualizer.getTotalSize()}px`);
  }, [rowVirtualizer.getTotalSize()]);

  return (
    <Box ref={dropdownRef}>
      <Box ref={searchWrapperRef}>
        <Search placeholder={placeholder} isAutoFocus={isAutoFocus} search={search} onFocus={dropdownHandler.open} searchHandler={onSearch}/>
      </Box>
      <Box sx={{
        position: 'relative',
        marginTop: '4px',
      }}>
        {dropdownState && <Box className={classes.dropdown}>
          <ScrollArea.Autosize
            data-select
            mah={dropdownHeight}
            viewportRef={scrollAreaRef}
            placeholder={''}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          >
            <Box className={classes.dropdown__scrollableArea}>
              {renderData.length < 1 && <Box className={classes.notFound}>Not found</Box>}
              <Box
                className={styles.select__virtualContainer}
              >
                {rowVirtualizer.getVirtualItems().map((virtualRow) => {
                  const {value, label, image, title, checked, badges} = renderData[virtualRow.index] || {};
                  return <VirtualSelectItem
                    key={virtualRow.key}
                    start={virtualRow.start}
                    size={virtualRow.size}
                    title={label}
                    image={image}
                    checked={checked}
                    onClick={onChange}
                    value={value}
                    withCheckboxes={withCheckboxes}
                    badges={badges}
                  />

                })}
              </Box>
            </Box>
          </ScrollArea.Autosize>
          {dropdownBottom && <Box>
            {dropdownBottom}
          </Box>}
        </Box>}
      </Box>
    </Box>
  );
};
