import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  forwardRef,
  memo
} from 'react';
import {Avatar, Box, Image, Skeleton} from '@mantine/core';
import type { ImageProps } from '@mantine/core';
import { IconPhotoOff } from '@tabler/icons-react';
import clsx from 'clsx';
import styles from './AppImage.module.scss';
import {BoxProps} from "@mantine/core/lib/Box/Box";
import {MantineSize} from "@mantine/styles/lib/theme/types/MantineSize";

export interface AppImageProps extends BoxProps {
  loading?: 'lazy' | 'eager';
  src?: ImageProps['src'],
  onLoad?: ImageProps['onLoad'],
  onError?: ImageProps['onError'],
  radius?: MantineSize,
  imageProps?: {
    loading?: 'lazy' | 'eager';
  } & ImageProps['imageProps'];
}

const Placeholder = memo(() => (
  <div className={styles.placeholder} role="img" aria-label="Image placeholder">
    <IconPhotoOff
      className={styles.placeholderIcon}
      aria-hidden="true"
      focusable="false"
    />
  </div>
));

const AppImageComponent = forwardRef<HTMLDivElement, AppImageProps>(
  ({
     src,
     onLoad,
     onError,
     className,
     style,
     loading: _loading,
     ...props
   }, ref) => {
    const loading = _loading || props.imageProps?.loading || 'lazy';
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    // Memoize dimensions to prevent unnecessary recalculations
    const dimensions = useMemo(() => ({
      width: props.w,
      height: props.h
    }), [props.w, props.h]);

    // State management with cleanup
    useEffect(() => {
      let isMounted = true;
      if (src) {
        setIsLoading(true);
        if (isMounted) setIsError(false);
      } else {
        if (isMounted) setIsLoading(false);
      }
      return () => { isMounted = false };
    }, [src]);

    // Stable event handlers
    const handleLoad = useCallback((e: React.SyntheticEvent<HTMLImageElement, Event>) => {
      setIsLoading(false);
      onLoad?.(e);
    }, [onLoad]);

    const handleError = useCallback((e: React.SyntheticEvent<HTMLImageElement, Event>) => {
      setIsError(true);
      setIsLoading(false);
      onError?.(e);
    }, [onError]);

    const imageProps = useMemo(() => ({
        ...props.imageProps,
        loading
    }), [props, loading]);

    // Memoized image component to prevent unnecessary rerenders
    const ImageComponent = useMemo(() => (
      <img
        {...props.imageProps}
        src={src || undefined}
        onLoad={handleLoad}
        onError={handleError}
        className={clsx(styles.image, {
          [styles.imageHidden]: isLoading || isError
        })}
        role={isError ? 'presentation' : undefined}
        aria-hidden={isLoading || isError}
        // width={dimensions.width}
        // height={dimensions.height}
      />
    ), [src, handleLoad, handleError, isLoading, isError, ref, dimensions, props.imageProps]);

    // Memoized skeleton component
    const SkeletonComponent = useMemo(() => (
      isLoading && (
        <Skeleton
          className={styles.skeleton}
          radius={props.radius}
          aria-busy="true"
          aria-live="polite"
        />
      )
    ), [isLoading, props.radius]);


    return (
      <Box
        className={clsx(styles.container, className)}
        style={style}
        w={dimensions.width}
        h={dimensions.height}
        sx={(theme) => ({
          borderRadius: props.radius ? theme.radius[props.radius] : undefined,
          overflow: 'hidden',
        })}
        role="figure"
        {...props}
      >
        {src ? (
          <>
            {ImageComponent}
            {SkeletonComponent}
            {isError && <Placeholder />}
          </>
        ) : (
          <Placeholder />
        )}
      </Box>
    );
  }
);

export const AppImage = memo(AppImageComponent);
AppImage.displayName = 'AppImage';
