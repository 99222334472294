import { AxiosRequestConfig } from 'axios';
import { apiClient } from '../../client';

interface Config extends AxiosRequestConfig {
  videoId: string;
}

export type  GetTaggedProductsWithVideoResponse = {
  id: string;
  product_id: string;
  product_type: string | null;
  image: string | null;
  title: string;
  myshopify_domain: string | null;
  price?: number;
  collectionIds: string[];
  isTagged: boolean;
  storeStatus?: 'active' | 'draft' | 'archived'
  isAvailableInOnlineStore?: boolean,
  isAvailableForSale?: boolean,
}[]

export const getAllProductsWithTaggedVideo = ({ videoId, ...config }: Config) =>
  apiClient.get<GetTaggedProductsWithVideoResponse>(`/bff/video/platform/${videoId}/all-products-with-tag`, config);
