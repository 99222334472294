import {useQuery} from "@tanstack/react-query";
import {getShopifyAuthLink} from "../../api/auth/getShopifyAuthLink";
import {queryKey} from "../query/keys";

export const useGetShopifyAuthLink = () => {
  return  useQuery({
    queryKey: queryKey.widgetsInfinite,
    queryFn: ()=>getShopifyAuthLink(),
    retry: true,
  })
}
