import {useQuery} from "@tanstack/react-query";
import {queryKey} from "../query/keys";
import {getProductsImportingStatus} from "../../api/products/getProductsImportingStatus";

export const useGetProductsImportingStatus = () => {
  return useQuery({
    queryKey: queryKey.productsImportingStatus,
    queryFn: async () => {
      return getProductsImportingStatus().then((res) => res.data);
    },
  })
}
