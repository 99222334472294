
const productParameterBadgeTexts: {
  [key: string]: {
    [value: string]: string;
  };
} = {
  isAvailableInOnlineStore: {
    false: 'Offline',
  },
  isAvailableForSale: {
    false: 'Unsellable',
  },
  storeStatus: {
    draft: 'Draft',
    archived: 'Archived',
    active: 'Active',
  },
}

const getBadgeText = (key: string, value?: string | boolean | undefined | null) => {
  try {
    const validValue = value?.toString();
    if(!validValue) {
      return undefined;
    }
    return productParameterBadgeTexts?.[key]?.[validValue];
  } catch (e) {
    return undefined
  }
}
interface  ProductBadgesProperties {
  storeStatus?: 'active' | 'draft' | 'archived'
  isAvailableInOnlineStore?: boolean,
  isAvailableForSale?: boolean,
}
export const getBadgesFromProduct = (data: ProductBadgesProperties) => {
  const badges: {text: string
  key: keyof ProductBadgesProperties;
  value: string | boolean | undefined | null
  }[] = [];

  Object.entries(data).forEach(([_key, value]) => {
    const key: keyof ProductBadgesProperties = _key as keyof ProductBadgesProperties;
    const badgeText = getBadgeText(key, value);
    if(badgeText) {
      badges.push({
        text: badgeText,
        key,
        value,
      });
    }
  });
  return badges;
}
