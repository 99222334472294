import React from 'react';
import {Box, Center, Container, Text, useMantineTheme} from '@mantine/core';
import {GradientButton} from "../../components/GradientButton/GradientButtom";
import {useGetShopifyAuthLink} from "../../shared/hooks/useGetShopifyAuthLink";

export const Unauthorized = () => {
  const theme = useMantineTheme();
  const { data: resReLoginLink} = useGetShopifyAuthLink()
  const reLoginLink = resReLoginLink?.data?.link


  return (
    <Container w="100vw" h="100vh">
      <Box
        sx={{
          paddingTop: 10,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Text
          sx={{
            fontSize: 32,
            fontWeight: 600,
            marginBottom: 20,
            textAlign: 'center',
            color: theme.colors.whitePrimary,
          }}
        >
          Unauthorized <br /> Please login via Shopify
        </Text>
      </Box>
      {reLoginLink && <Center pt={2}>
        <a href={reLoginLink}>
          <GradientButton>
            Re-login
          </GradientButton>
        </a>
      </Center>}
    </Container>
  );
};
