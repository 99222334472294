import { Badge, Box, Tooltip } from '@mantine/core';
import { ReactComponent as IconManual } from 'src/shared/icons/settings-manual.svg';
import { ReactComponent as IconHome } from 'src/shared/icons/home.svg';
import { ReactComponent as IconTag } from 'src/shared/icons/tag.svg';
import { ReactComponent as IconTags } from 'src/shared/icons/tags.svg';
import {
  IconWand,
  IconBrandInstagram,
  IconCategory,
  IconTargetArrow,
  IconUpload
} from '@tabler/icons-react';
import styles from './WidgetCardInnerBottom.module.scss'
import { FC } from 'react'
import { PageType, WidgetBlockStatus, WidgetStatus } from 'src/components/Widget/types';
import { Link } from 'react-router-dom';

interface WidgetCardInnerBottomProps {
  widgetId: string
  source: 'instagram' | 'upload' | 'mixed' | null
  isCreatedManually: boolean
  status: WidgetStatus
  blockStatuses: WidgetBlockStatus[]
  page: PageType
  allPages: boolean
}

interface BadgeData {
  props: {
    color: string
    variant?: string
    className: string
  },
  label: string
  tooltipText: string
  targetTab: string
  currentWidgetId: string
  isError: boolean
}

export const WidgetCardInnerBottom: FC<WidgetCardInnerBottomProps> = (props) => {
  const { source, isCreatedManually, status, page, allPages, blockStatuses, widgetId } = props

  const isNotInstalled = blockStatuses.includes(WidgetBlockStatus.notInstalled)
  const isWidgetHidden = blockStatuses.includes(WidgetBlockStatus.widgetIsHidden)
  const isParentHidden = blockStatuses.includes(WidgetBlockStatus.parentBlockIsHidden)

  const badgeData = (() => {
    if (isNotInstalled) {
      return {
        props: {
          color: 'greyLight.2',
          className: styles.badge__notInstalled,
        },
        label: "Not Installed",
        tooltipText: "The code hasn't been installed",
        targetTab: 'installation',
        currentWidgetId: widgetId,
        isError: true
      } as BadgeData
    } else if (isParentHidden) {
      return {
        props: {
          color: 'red',
          className: styles.badge__error
        },
        label: "Parent Hidden",
        tooltipText: "Parent block is hidden",
        targetTab: 'installation',
        currentWidgetId: widgetId,
        isError: true
      } as BadgeData
    } else if (isWidgetHidden) {
      return {
        props: {
          color: 'red',
          className: styles.badge__error
        },
        label: "Widget Hidden",
        tooltipText: "Widget block is hidden",
        targetTab: 'installation',
        currentWidgetId: widgetId,
        isError: true
      } as BadgeData
    }

    switch (status) {
      case WidgetStatus.draft:
        return {
          props: {
            color: 'blue',
            variant: 'light',
            className: styles.badge__notPublished
          },
          label: 'Not Published',
          isError: false
        } as BadgeData
      case WidgetStatus.published:
        return {
          props: {
            color: 'green.0',
            variant: 'light',
            className: styles.badge__active
          },
          label: 'Published',
          isError: false
        } as BadgeData
      default:
        return {
          props: {
            color: 'greyLight.2',
            className: styles.badge__notInstalled
          },
          label: 'Not Installed',
          isError: true
        } as BadgeData
    }
  })()


  const pageTooltipLabel = (() => {
    switch (page) {
      case PageType.home:
        return 'Home page'
      case PageType.product:
        return 'Product page'
      case PageType.collection:
        return 'Collection page'
      default:
        return ''
    }
  })()

  const sourceTooltipLabel = (() => {
    switch (source) {
      case 'instagram':
        return 'Videos from Instagram'
      case 'upload':
        return 'Uploaded videos'
      case 'mixed':
        return 'Mixed videos'
      default:
        return ''
    }
  })()



  return (
    <div className={styles.wrapper}>
      {badgeData.isError ? (
        <Tooltip
          multiline
          w={214}
          label={
            <Box className={styles.tooltip}>
              {badgeData.tooltipText},<br/>
              {isNotInstalled ? (
              <>
                please do{' '}
                <Link
                  className={styles.tooltip__link}
                  to={`/widgets/edit/${badgeData.currentWidgetId}?defaultTab=${badgeData.targetTab}`}
                  onClick={(e) => {e.stopPropagation()}}
                >
                  this
                </Link>{' '}
              </>
              ) : (
              <>
                please click{' '}
                <b>Unhide </b>
              </>
              )}
              to fix it.
            </Box>
          }
          position="top"
          withArrow
          closeDelay={1000}
        >
        <Badge {...badgeData.props}>
          {badgeData.label}
        </Badge>
        </Tooltip>
        ) : (
        <Badge {...badgeData.props}>
          {badgeData.label}
        </Badge>)
      }
      <div className={styles.icons}>
        {!allPages ?
          <Tooltip
            label="Target to specific page"
            position="bottom"
            withArrow
          >
            <div className={styles.iconWrapper}><IconTargetArrow strokeWidth={1.5} /></div>
          </Tooltip>
        : null}

        {source ?
          <Tooltip
            label={sourceTooltipLabel}
            position="bottom"
            withArrow
          >
            <div className={styles.iconWrapper}>
              {source === 'instagram' ? <IconBrandInstagram strokeWidth={1.5} /> : null}
              {source === 'upload' ? <IconUpload strokeWidth={1.5} /> : null}
              {source === 'mixed' ? <IconCategory strokeWidth={1.5} /> : null}
            </div>
          </Tooltip>
        : null}

        <Tooltip
          label={pageTooltipLabel}
          position="bottom"
          withArrow
        >
          <div className={styles.iconWrapper}>
            {page === PageType.home ? <IconHome /> : null}
            {page === PageType.product ? <IconTag /> : null}
            {page === PageType.collection ? <IconTags /> : null}
          </div>
        </Tooltip>

        <Tooltip
          label={isCreatedManually ? 'Created manually' : 'Created automatically'}
          position="bottom"
          withArrow
        >
          <div className={styles.iconWrapper}>
            {isCreatedManually ? <IconManual /> : <IconWand color="#C1C2C5" strokeWidth={1.5} />}
          </div>
        </Tooltip>
      </div>
    </div>
  )
}

