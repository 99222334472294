import { Box, createStyles, Portal, Text } from '@mantine/core';
import { VideoCard } from '../../../../VideoCard/VideoCard';
import { VideoResponseDeprecated } from '../../../../Library/LibraryHeader/types';
import React, {FC, useCallback, useMemo, useState} from 'react';
import { ConfirmDeletionModal } from 'src/components/Library/ConfirmDeletionModal';
import { useDisclosure } from '@mantine/hooks';
import { useDispatch } from 'react-redux';
import { widgetsActions } from 'src/components/Widget/store';
import { SelectedVideosListManager } from './SelectedVideosListManager/SelectedVideosListManager';
import {WidgetVideo} from "../../../types";
import styles from "../Settings/ProductDetails/ProductDetailsSettings.module.scss";
import {IconGripVertical} from "@tabler/icons-react";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverlay,
  DragStartEvent,
} from '@dnd-kit/core';
import {
  arrayMove, rectSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates, useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import type {DragEndEvent} from "@dnd-kit/core/dist/types";
import { CSS } from "@dnd-kit/utilities";
import {VideoCardProps} from "../../../../VideoCard/VideoCard.types";
import {DraggableVideoList} from "./DraggableVideoList/DraggableVideoList";


const useStyles = createStyles((theme) => ({
  noVideosText: {
    color: theme.colors.whitePrimary,
    textAlign: 'center',
    fontFamily: '"Segoe UI", sans-serif',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '110%',
  },
  noVideosTextContainer: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
  },
  cardsContainer: {
    display: 'flex',
    width: '100%',
    flexGrow: 1,
    flex: 1,
    flexWrap: 'wrap',
    gap: '35px 26px',
    position: 'relative',
    minHeight: '100%',
  },
}));

interface Props {
  emptyText: string;
  videos: WidgetVideo[];
  addedAutomatically?: boolean;
  draggable?: boolean;
  canDelete?: boolean;
}

export const SelectedVideosList: FC<Props> = ({ emptyText, videos, addedAutomatically, draggable, canDelete }) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const [confirmationModalState, setConfirmationModalState] = useDisclosure(false);

  const [selectedVideos, setSelectedVideos] = useState<string[]>([]);
  const [videoId, setVideoId] = useState<VideoResponseDeprecated['id'] | null>(null);

  const deleteVideo = (id: string) => {
    setVideoId(id);
    setConfirmationModalState.open();
  };

  const confirmDeleteVideoFromWidgetVideos = () => {
    if (!videoId) return;
    dispatch(widgetsActions.deleteVideosFromWidget([videoId]));
    setVideoId(null);
    setConfirmationModalState.close();
  };

  const checkVideoHandler = (id: string) => {
    if (selectedVideos.includes(id)) {
      setSelectedVideos((prev) => prev.filter((videoId) => videoId !== id));
    } else {
      setSelectedVideos((prev) => [...prev, id]);
    }
  }



  return (
    <>
      < >
        <Box className={classes.cardsContainer} data-c="selected-video">
          {videos.length < 1 && (
            <Box className={classes.noVideosTextContainer}>
              <Text className={classes.noVideosText}>{emptyText}</Text>
            </Box>
          )}
          {videos.length > 0 &&
            draggable ?
            <>
              <DraggableVideoList
                videos={videos}
                renderVideo={({video}) => (
                <VideoCard
                  hasDeleteButton={canDelete}
                  key={video.id}
                  id={video.id}
                  muxId={video.mux ?? undefined}
                  title={video.title}
                  state="Data"
                  type={video.type}
                  checked={selectedVideos.includes(video.id)}
                  duration={video.duration ?? '00:00'}
                  onChecked={!addedAutomatically ? checkVideoHandler : undefined}
                  onDelete={!addedAutomatically ? deleteVideo : undefined}
                  readOnly={true}
                  buttonText="Tag products"
                  isShoppable={video.isShoppable}
                />
                )}
              />
            </>
            :
            videos.map((vid) => (
              <VideoCard
                key={vid.id}
                id={vid.id}
                muxId={vid.mux ?? undefined}
                title={vid.title}
                state="Data"
                type={vid.type}
                checked={selectedVideos.includes(vid.id)}
                duration={vid.duration ?? '00:00'}
                onChecked={!addedAutomatically ? checkVideoHandler : undefined}
                onDelete={!addedAutomatically ? deleteVideo : undefined}
                readOnly={true}
                buttonText="Tag products"
              />
            ))}
        </Box>
        <ConfirmDeletionModal
          onClose={setConfirmationModalState.close}
          open={confirmationModalState}
          onCancel={setConfirmationModalState.close}
          onConfirm={confirmDeleteVideoFromWidgetVideos}
          bodyText={
            "Are you sure you want to remove 1 video from the widget? This action doesn't delete video from the library."
          }
          title="Delete video"
        />
      </ >

      {selectedVideos.length ?
        <SelectedVideosListManager
          selectedVideos={selectedVideos}
          onCancel={() => setSelectedVideos([])}
        />
        : null}
    </>
  );
};
