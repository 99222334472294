import {
  Box,
  createStyles,
  Select,
  Text,
  Image,
  SelectItemProps as MantineSelectItemProps,
  ActionIcon,
  ScrollArea, Portal,
} from '@mantine/core';
import classNames from 'classnames';
import {FC, forwardRef, useEffect, useMemo, useRef, useState} from 'react';
import { SelectedVideosList } from '../SelectedVideosList';
import { VideoResponseDeprecated } from '../../../../../Library/LibraryHeader/types';
import {PageType, WidgetVideo} from 'src/components/Widget/types';
import { usePagesData } from 'src/shared/hooks/usePagesData';
import { IconX, IconPhoto } from '@tabler/icons-react';
import { ReactComponent as SelectIcon } from 'src/shared/icons/select-icon.svg';
import {
  SelectWithCheckboxes
} from "../../../../../../shared/components/SearchableSelectWithCheckboxes/SelectWithCheckboxes";
import {SelectData} from "../../../../../../shared/components/SearchableSelectWithCheckboxes/interface";
import {useDisclosure} from "@mantine/hooks";
import {AppImage} from "../../../../../../shared/components/AppImage/AppImage";

const useStyles = createStyles((theme) => ({
  select: {
    width: '320px',
  },
  selectItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px 10px',
  },
  inputRightSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '72px',
  },
  select__iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    marginRight: '14px',
  },
  emptyIconBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '56px',
    height: '56px',
    marginRight: '16px',
    borderRadius: '4px',
    backgroundColor: '#fff',
    color: theme.colors.greyDark[0]
  }
}));

interface SelectItemProps extends MantineSelectItemProps {
  image: string;
  title: string;
}
const SelectItem = forwardRef<HTMLDivElement, SelectItemProps>(
  ({ image, title, className, ...props }: SelectItemProps, ref) => {
    const { classes } = useStyles();
    const selectItemStyles = classNames(className, classes.selectItem);

    return (
      <Box {...props} className={selectItemStyles}>
        {image ?
          <AppImage src={image} w={56} h={56} mr={16} radius="sm" loading="lazy"/>
        :
          <Box className={classes.emptyIconBox}>
            <IconPhoto width={28} height={28} />
          </Box>
        }
        <Text color="greyLight.2">{title}</Text>
      </Box>
    );
  }
);

interface OptionAutomatedProps {
  changePage: (val: string) => void;
  currentPage?: string;
  videos: WidgetVideo[];
  pageType: Exclude<PageType, PageType.home>;
}
export const OptionAutomated: FC<OptionAutomatedProps> = ({
  changePage,
  currentPage,
  videos,
  pageType,
}) => {
  const { classes } = useStyles();
  const { selectData } = usePagesData();
  const [search, setSearch] = useState<string>('');
  const prevSelectedPageValue = useRef<string>();

  const filteredSelectData: SelectData[] = useMemo(()=>{
    const shallowCopy: SelectData[] = [...selectData];
    let isPrevSelectPage = false;
    let isCurrentSelectPage = false;
    for (let i = 0; i < shallowCopy.length; i++) {
      if (shallowCopy[i].value === prevSelectedPageValue.current) {
        shallowCopy[i].checked = false;
        isPrevSelectPage = true
      }
      if (shallowCopy[i].value === currentPage) {
        shallowCopy[i].checked = true;
        isCurrentSelectPage = true
      }
      if (isCurrentSelectPage && isPrevSelectPage) {
        break;
      }
    }


   return shallowCopy.filter((item) => item.label.toLowerCase().includes(search.toLowerCase()))
  },[selectData, search, currentPage]);

  const targetPage = pageType === PageType.collection ? 'Collection' : 'Product';
  const onChange = ({value}: {value: string }) => {
    prevSelectedPageValue.current = currentPage;
    changePage(value);
  }

  useEffect(()=>{
    const selectedPage = selectData.find((item) => item.value === currentPage);
    setSearch(selectedPage?.title ?? '')
  },[currentPage, selectData])
  const [dropdownState, dropdownHandler] = useDisclosure()
  return (
    <Box mt={12}>
      {currentPage && (
       <Portal target="#tabs-portal">
         <Box sx={{
           padding: '28px 30px',
         }}>
           <SelectedVideosList videos={videos} emptyText="No videos tagged" addedAutomatically />
         </Box>
       </Portal>
      )}
      <Text color="greyLight.2" mb={18} w={378}>
        Based on tagged products, VideoPoint will automatically match your videos to the correct pages (Product, Collection)
        and continuously update them with newly tagged videos.
      </Text>
      <Text color="greyLight.2">Select {targetPage} page to preview tagged videos per page</Text>
      <Box>

      </Box>
    <Box w={320}>
        <SelectWithCheckboxes
          selectData={filteredSelectData}
          onChange={onChange}
          search={search}
          onSearch={setSearch}
          withCheckboxes={false}
          dropdownHandler={dropdownHandler}
          dropdownState={dropdownState}
        />
    </Box>
    </Box>
  );
};
