import { Box, Text, Image, Button, createStyles, Checkbox } from '@mantine/core';
import { FC } from 'react';
import {AppImage} from "../../../../../shared/components/AppImage/AppImage";

const useStyles = createStyles((theme) => ({
  selectItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '4px 12px 4px 10px',
    borderRadius: '4px',
    cursor: 'pointer',
    height: '67px',
    '&:not(:last-child)': {
      marginBottom: '4px',
    },
    '&[data-selected="true"]': {
      backgroundColor: theme.colors.greyDark[3],
    },
    '&:hover': {
      backgroundColor: theme.colors.greyDark[3],
    },
  },
  selectItem__infoWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  selectDropdown__buttonsWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '12px 17px',
  },
}));

interface SelectItemProps {
  onClick: () => void;
  checked: boolean;
  image: string | null;
  title: string;
}
export const SelectItem: FC<SelectItemProps> = ({ onClick, checked, image, title }) => {
  const { classes } = useStyles();
  return (
    <Box className={classes.selectItem} onClick={onClick} data-selected={checked}>
      <Box className={classes.selectItem__infoWrapper}>
        <AppImage src={image}  w={56} h={56} mr={16} radius="sm" loading="lazy" />
        <Text color="greyLight.2">{title}</Text>
      </Box>
      <Checkbox checked={checked} color="green.1" onChange={onClick} />
    </Box>
  );
};

interface SelectDropdownButtonsProps {
  onCancel: () => void;
  onConfirm: () => void;
  disableConfirmButton: boolean;
}
export const SelectDropdownButtons: FC<SelectDropdownButtonsProps> = ({
  onCancel,
  onConfirm,
  disableConfirmButton,
}) => {
  const { classes } = useStyles();
  return (
    <Box className={classes.selectDropdown__buttonsWrapper}>
      <Button variant="default" mr={12} onClick={onCancel}>
        Cancel
      </Button>
      <Button color="green.1" onClick={onConfirm} disabled={disableConfirmButton}>
        Save
      </Button>
    </Box>
  );
};
