import { apiClient } from '../client';
import { AxiosRequestConfig } from 'axios';


type Data = {
  productCountOnPlatform: number;
  productImportingStatus?: {
    id: string;
    userId: string;
    status: 'Started' | 'Completed' ;
    isShowMessage: boolean;
    storeProductCount: number;
    createdAt: string; // ISO 8601 date string
    updatedAt: string; // ISO 8601 date string
  };
};


export const getProductsImportingStatus = (config?: AxiosRequestConfig) =>
  apiClient.get<Data>('/bff/products/product-importing-status', config);
