import styles from './Library.module.scss';
import {
  LibraryLayout,
  MainVideoFilter,
  SourceFilter,
  SubFilter,
  VideoFilter
} from '../../components/Library/LibraryLayout/LibraryLayout';
import {LibraryHeader} from 'src/components/Library/LibraryHeader/LibraryHeader';
import {useDispatch, useSelector} from 'react-redux';
import React, {useEffect, useRef, useState} from 'react';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {videoListActions} from 'src/components/Library/store';
import {LibraryVideoList} from 'src/components/Library/LibraryVideoList/LibraryVideoList';
import {Box, Center, createStyles, Loader, ScrollArea} from '@mantine/core';
import {useCheckInstagramConnection} from 'src/shared/hooks/useCheckInstagramConnection';
import {LoginScreen} from "../../components/LoginScreen/LoginScreen";
import {VideoFileUploader} from "../../components/VideoFileUploader/VideoFileUploader/VideoFileUploader";
import {userActions} from "../../components/User/store";
import {VideosUploadingLoader} from "../../components/Library/VideosUploadingLoader/VideosUploadingLoader";
import {useVideosCountForAllFilters} from "../../shared/hooks/useVideosCountForAllFilters";
import {useVideos} from "../../shared/hooks/useVideos";
import {videoFilterToVideoQueryFilter} from "../../shared/helpers/videoFilterToVideoQueryFilter";
import {useGetUser} from "../../shared/hooks/tanstackQuery/useGetUser";
import {selectUser} from "../../components/User/selectors";
import {useGetAITaggingStatus} from "../../shared/hooks/useGetAITaggingStatus";
import {notifications} from "@mantine/notifications";
import {IconCheck, IconSparkles} from "@tabler/icons-react";
import {usePutITaggingStatus} from "../../shared/hooks/usePutITaggingStatus";
import {
  ProductsImportingStatusNotification
} from "../../components/Products/ProductsImportingStatus/ProductsImportingStatusNotification";

const useStyles = createStyles((theme) => ({
  scrollbar: {
    backgroundColor: 'transparent',
    padding: 2,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&[data-orientation="vertical"]': {
      width: '8px',
      '.mantine-ScrollArea-thumb': {
        borderRadius: '100px',
        backgroundColor: 'rgba(193, 194, 197, 0.20)',
      },
    },
  },
}));

const Content: React.FC<{
  instagram_token: string| null;
  isAnyVideo: boolean;
  currentFilter: VideoFilter;
}> = ({isAnyVideo, instagram_token, currentFilter}) => {
  const { classes } = useStyles();
  const user = useSelector(selectUser);
  const isUploadVideos = currentFilter.type === MainVideoFilter.Source && currentFilter.subFilter?.includes(SourceFilter.Upload);



  const scrollAreaRef = React.useRef<HTMLDivElement>(null);
  const videoQueryFilter = videoFilterToVideoQueryFilter(currentFilter)
  const {
    data: videoPages,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    isLoading: isVideoLoading,
  } = useVideos({
    videoQueryFilter
  });
  const isShowLoader = isVideoLoading;
  const videos = videoPages ? videoPages.pages.flat(2) : [];
  const isShowDropzone = isUploadVideos && videos.length === 0;

  if(isShowLoader) {
    return <VideosUploadingLoader />
  }

  return (
    <>
      <LibraryHeader videosByFilter={videos} currentFilter={currentFilter} />
      {isShowDropzone ? (
        <VideoFileUploader />
      ) : (
        <ScrollArea
          h={'calc(100vh - 60px)'}
          type="auto"
          viewportRef={scrollAreaRef}
          classNames={{
            scrollbar: classes.scrollbar,
          }}
        >
          <Box className={styles.scrollableAreaContent}>
            <LibraryVideoList
              videos={videos}
              isFetchingNextPage={isFetchingNextPage}
              hasNextPage={hasNextPage}
              fetchNextPage={fetchNextPage}
              parentRef={scrollAreaRef}
              currentFilter={currentFilter}
            />
          </Box>
        </ScrollArea>
      )}
    </>
  );
};


export const Library = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();




  const { instagram_token, loading } = useCheckInstagramConnection();
  const { filter,  } = useParams<{ filter: MainVideoFilter}>();
  const [searchParams] = useSearchParams();
  const [currentFilter, setCurrentFilter] = useState<VideoFilter>(() => {
    const subFilters = searchParams.get('subFilters') as SubFilter | undefined;
    return {
      type: filter ?? MainVideoFilter.Inbox,
      subFilter: subFilters ? [subFilters] : [],
    }
  });

  const navigate = useNavigate();
  const onChangeFilter = (filter: VideoFilter) => {
    const mainFilter = filter.type;
    const subFilters = filter.subFilter || [];
    const subFiltersString = subFilters.length > 0 ? `?subFilters=${subFilters.join(',')}` : ''
    navigate(`/library/${mainFilter}${subFiltersString}`);
  }

  const [videosLoading, setVideoLoading] = useState(true);

  useEffect(() => {
    if (!filter) return;
    const subFilters = searchParams.get('subFilters') as SubFilter | undefined;

    dispatch(videoListActions.uncheckAll())

    setCurrentFilter({
      type: filter,
      subFilter: subFilters ? [subFilters] : [],
    });

  }, [filter, searchParams]);




  const {data: videoCount, isLoading: isVideoCountLoading} = useVideosCountForAllFilters();
  const isAnyVideo = videoCount ? Object.values(videoCount).map((count) => count > 0).some(Boolean) : false;

  const user = useSelector(selectUser);
  const isShowingLoginScreen = (!isAnyVideo && !instagram_token) || user.isWaitingVideosFromInstagram;
  const isShowLoader = loading || isVideoCountLoading;

  const {data: aiTaggingStatus} = useGetAITaggingStatus();
  const {mutate: writeAITaggingStatusWasShown} = usePutITaggingStatus();
  const closedAITaggingStatusNotificationStartedNotificationId = useRef<string>();
  const isShowedAITaggingStatusNotificationCompleted = useRef(false);

  useEffect(()=>{
    if(!aiTaggingStatus) {
      return;
    }
    if(!aiTaggingStatus.storeAITaggingStatuses.isShowMessage) {
      return;
    }
    const startNotificationId = `${aiTaggingStatus.storeAITaggingStatuses.id}-started-${aiTaggingStatus.countTaggedProductsToVideo}-${aiTaggingStatus.countVideosWithAITaggedProducts}`
    const isWasClosed = Boolean(closedAITaggingStatusNotificationStartedNotificationId.current) && closedAITaggingStatusNotificationStartedNotificationId.current !== startNotificationId;
    if(aiTaggingStatus.storeAITaggingStatuses.status === 'Started' && !isWasClosed) {
      notifications.show({
        id: startNotificationId,
        autoClose: false,
        title: 'AI-tagging is running in the background, making your videos shoppable automatically',
        message: 'You can safely close this notification',
        icon: <Loader/>,
        onClose() {
          closedAITaggingStatusNotificationStartedNotificationId.current = startNotificationId;
        },
        styles: (theme) => ({
          icon: {
            backgroundColor: 'transparent',
          },
          root: {
            backgroundColor: theme.colors.greyDark[5]
          },
        })
      })

    } else if(aiTaggingStatus.storeAITaggingStatuses.status === 'Completed') {
      isShowedAITaggingStatusNotificationCompleted.current = true;
      notifications.hide(startNotificationId)
      notifications.show({
        id: `${aiTaggingStatus.storeAITaggingStatuses.id}-completed`,
        autoClose: false,
        onClose() {
          writeAITaggingStatusWasShown()
        },
        title: `AI has made ${aiTaggingStatus.countVideosWithAITaggedProducts} videos shoppable by tagging ${aiTaggingStatus.countTaggedProductsToVideo} products`,
        message: '',
        icon: <IconSparkles size={25} stroke={1}/>,
        styles: (theme) => ({
          icon: {
            backgroundColor: 'transparent',
          },
          root: {
            backgroundColor: theme.colors.greyDark[5]
          },
        })
      })
    }
  }, [aiTaggingStatus])

  return (
    <div className={styles.container}>
      <ProductsImportingStatusNotification/>
      {isShowLoader ? <Center h="100%" w="100%" > <Loader size="xl" /> </Center> :
       isShowingLoginScreen ?
        <LoginScreen />
        :  <>
        {!loading && (
          <LibraryLayout currentFilter={currentFilter} filterOnChange={onChangeFilter}/>
        )}
        <div className={styles.content}>
          <Content instagram_token={instagram_token ?? null} isAnyVideo={isAnyVideo} currentFilter={currentFilter}/>
        </div>
      </>
      }
    </div>
  );
};
