import React, {FC, forwardRef, PropsWithChildren, RefCallback, useEffect, useImperativeHandle, useRef} from 'react';
import { Box, createStyles, Skeleton, Flex } from '@mantine/core';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import {
  selectWidgetSettingsGeneral,
  selectWidgetSettingsLayout,
} from 'src/components/Widget/selectors';
import { widgetSettingsInit } from 'src/components/Widget/store';
import { WidgetViewType } from 'src/components/Widget/types';
import { CarouselSkeletonFooter } from './components/CarouselSkeletonFooter';
import { StoriesSkeletonFooter } from './components/StoriesSkeletonFooter';

import styles from './MobileView.module.scss';
import {useScrollIntoView} from "@mantine/hooks";

const useStyles = createStyles((theme) => ({
  root: {
    position: 'relative',
    minHeight: '500px',
    border: `5px solid ${theme.colors.greyDark[1]}`,
    borderRadius: '6px',
    overflow: 'hidden',
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 1,
      right: 1,
      left: 1,
      bottom: 1,
      border: `1px solid ${theme.colors.greyDark[1]}`,
      borderRadius: '6px',
    },
  },
  content: {
    '::-webkit-scrollbar': {
    display: 'none',
  },
    backgroundColor: '#fff',
    position: 'absolute',
    top: 1,
    right: 1,
    left: 1,
    bottom: 1,
    border: `1px solid ${theme.colors.greyDark[1]}`,
    borderRadius: '6px',
    overflowX: 'hidden',
  },
}));

interface MobileViewProps {
  children: React.ReactNode;
  getViewport?: (ref: HTMLDivElement | null) => void;
  isShowSkeleton?: boolean;
}

export const MobileView: React.FC<MobileViewProps> =(props, ref) => {
  const {targetRef: targetScroll, scrollableRef: scrollAreaRef, scrollIntoView} = useScrollIntoView<HTMLDivElement>({
    duration: 250,
  })
  const { children, getViewport, isShowSkeleton = true } = props
  const elementRef = useRef<HTMLDivElement>(null);
  useEffect(()=>{
    if(getViewport) {
      getViewport(elementRef?.current)
    }
  },[])


  const { classes } = useStyles();
  const layout = useSelector(selectWidgetSettingsLayout) ?? widgetSettingsInit.layout;
  const { widgetViewType } = useSelector(selectWidgetSettingsGeneral) ?? widgetSettingsInit.general;

  const initialWidgetAlignment = useRef(false);

  useEffect(() => {
    scrollIntoView({
      alignment: 'center',
    })
    if(!initialWidgetAlignment.current) {
      const timeout = setTimeout(() => {
        if(!initialWidgetAlignment.current) {
          scrollIntoView({
            alignment: 'center',
          })
        }
      }, 700);
      return () => clearTimeout(timeout);
    }
    initialWidgetAlignment.current = true;
  }, [children]);

  return (
    <Box
      ref={elementRef}

      className={classNames(
      classes.root,
      styles.wrapper,
      styles[`wrapper__${widgetViewType}`],
    )}>
      <Box
        ref={scrollAreaRef}
        className={classes.content}
      >
       {isShowSkeleton && <>
          <Flex align="center" justify="center" direction="column" mt={26}>
            <Skeleton width="50%" height={6} mb={15}/>
            <Skeleton width="80%" height={6} mb={15}/>
          </Flex>

          <Flex align="flex-end" p={12} mb={6}>
            <Skeleton height={90} width={90} radius="md" mr={12}/>
            <Flex direction="column" sx={{flexGrow: 1}}>
              <Skeleton width="100%" height={6} mb={12}/>
              <Skeleton width="100%" height={6} mb={12}/>
              <Skeleton width="100%" height={6} mb={12}/>
              <Skeleton width="100%" height={6} mb={12}/>
              <Skeleton width="45%" height={6}/>
            </Flex>
          </Flex>
          <Box w="100%" px={12}>
            <Skeleton width="100%" height={24} mb={12}/>
            <Skeleton width="100%" height={18} mb={6}/>
            <Skeleton width="100%" height={20} mb={6}/>
          </Box>
        </>}
        <Box className={styles.widgetWrapper}  px={12} w="100%" h={!isShowSkeleton ? '100%' : undefined} >
          <Box data-type="view-widget-wrapper" >
            {children}
            {children && <Box className={styles.widgetCenter} ref={targetScroll} data-type="view-widget-center"/>}
          </Box>
        </Box>

        { isShowSkeleton &&<>
          {widgetViewType === WidgetViewType.stories ? (
            <StoriesSkeletonFooter size={layout.size} showHeading={layout.showHeading}/>
          ) : null}
          {widgetViewType === WidgetViewType.carousel ? (
            <CarouselSkeletonFooter
              size={layout.size}
              showDots={layout.showDots}
              showHeading={layout.showHeading}
            />
          ) : null}
        </>}
      </Box>
    </Box>
  );
}
