import React, { useEffect, useRef } from 'react';
import { useGetProductsImportingStatus } from '../../../shared/hooks/useGetProductsImportingStatus';
import { usePutITaggingStatus } from '../../../shared/hooks/usePutITaggingStatus';
import { notifications } from '@mantine/notifications';
import {Loader} from '@mantine/core';
import type {NotificationProps} from "@mantine/notifications/lib/types";
import {usePutProductsImportingStatus} from "../../../shared/hooks/usePutProductsImportingStatus";

export const ProductsImportingStatusNotification = () => {
  const { data: productImportingStatus, refetch } = useGetProductsImportingStatus();
  const { mutate: writeProductsImportingStatusWasShown } = usePutProductsImportingStatus();
  const closedProductsImportingStatusNotificationStartedNotificationId = useRef<string>();
  const isShowedProductsImportingNotificationCompleted = useRef(false);
  const { status, storeProductCount: invalidStoreProductCount } = productImportingStatus?.productImportingStatus || {};
  const productCountOnPlatform = productImportingStatus?.productCountOnPlatform || 0;
  const storeProductCount = invalidStoreProductCount || 0;

  const isUpdatedMoreThan15MinutesAgo = productImportingStatus?.productImportingStatus?.updatedAt ? new Date(productImportingStatus.productImportingStatus.updatedAt).getTime() < Date.now() - 15 * 60 * 1000 : false;
  const percentage =
    (storeProductCount > 0 && productCountOnPlatform > 0)
      ? Math.round((productCountOnPlatform / storeProductCount) * 100)
      : 0;
  const isAllProductsImported = percentage === 100;
  // const isCompleted = status === 'Completed' || isAllProductsImported || isUpdatedMoreThan15MinutesAgo;
  const isCompleted = status === 'Completed' || isAllProductsImported ;




  useEffect(() => {
    if (!productImportingStatus) return;
    if(!productImportingStatus.productImportingStatus) return;
    if (!productImportingStatus.productImportingStatus.isShowMessage) return;

    const { productImportingStatus: statusData } = productImportingStatus;
    const startNotificationId = `${statusData.id}-started-${statusData.storeProductCount}`;
    const isWasClosed =
      Boolean(closedProductsImportingStatusNotificationStartedNotificationId.current) &&
      closedProductsImportingStatusNotificationStartedNotificationId.current !== startNotificationId;

    if (!isCompleted && statusData.status === 'Started' && !isWasClosed) {
      // Calculate the percentage progress

      const text = `Products importing is in progress: ${percentage}%`;
      const notificationProps = {
        id: startNotificationId,
        autoClose: false,
        message: text,
        icon: <Loader />,
        onClose() {
          closedProductsImportingStatusNotificationStartedNotificationId.current = startNotificationId;
        },
        styles: (theme) => ({
          icon: {
            backgroundColor: 'transparent',
          },
          root: {
            backgroundColor: theme.colors.greyDark[5],
          },
        }),
      } satisfies NotificationProps
      notifications.show(notificationProps);
      notifications.update(notificationProps)
    } else if (isCompleted) {
      isShowedProductsImportingNotificationCompleted.current = true;
      // Hide any started notification
      notifications.hide(startNotificationId);
      notifications.show({
        id: `${statusData.id}-completed`,
        autoClose: false,
        onClose() {
          writeProductsImportingStatusWasShown();
        },
        title: `All ${statusData.storeProductCount} products have been imported`,
        message: '',
        styles: (theme) => ({
          icon: {
            backgroundColor: 'transparent',
          },
          root: {
            backgroundColor: theme.colors.greyDark[5],
          },
        }),
      });
    }
  }, [productImportingStatus, writeProductsImportingStatusWasShown, isCompleted]);

  const isFetchingRef = useRef(false);

  useEffect(() => {
    if(!productImportingStatus?.productImportingStatus && productCountOnPlatform > 0) {
      return
    }
    const timer = setInterval(async () => {
      if (!isCompleted && !isFetchingRef.current) {
        isFetchingRef.current = true;
        try {
          await refetch();
        } finally {
          isFetchingRef.current = false;
        }
      }
    }, 1_200);

    return () => clearInterval(timer);
  }, [isCompleted, refetch, productCountOnPlatform, productImportingStatus]);


  return null;
};
