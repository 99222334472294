import {useMutation} from "@tanstack/react-query";
import {queryClient} from "../../api/client";
import {queryKey} from "../query/keys";
import {putMessageAITaggingStatus} from "../../api/ai/putMessageAITaggingStatus";
import {putProductsImportingStatus} from "../../api/products/putProductsImportingStatus";

export const usePutProductsImportingStatus = () => {
  return useMutation({
    mutationFn: putProductsImportingStatus,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queryKey.productsImportingStatus
      });
    }
  })
}
