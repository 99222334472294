import { apiClient } from '../client';
import {AxiosRequestConfig} from "axios";
import {getEnv} from "../../shared/lib/getEnv";



interface Response {
  link: string
}
const isLocal = getEnv('REACT_APP_IS_LOCAL_RELOGIN')
export const getShopifyAuthLink = (config?: AxiosRequestConfig) => {
  return apiClient.get<Response>('/bff/auth/shopify-app-auth', {
    ...config,
    params: {
      ...config?.params,
      isLocal: isLocal ? 'true' : undefined
    }
  })
};
