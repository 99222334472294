import React, {FC, startTransition, useCallback, useState} from "react";
import {Avatar, Badge, Box, Button, Center, Checkbox, createStyles, Flex, Skeleton, Text} from "@mantine/core";
import {IconPhoto} from '@tabler/icons-react';
import styles from './SearchableSelectWithCheckboxes.module.scss';
import {AppImage} from "../AppImage/AppImage";


const useStyles = createStyles((theme) => ({
  imageContainer: {
    width: 56,
    minWidth: 56,
    maxWidth: 56,
    height: 56,
    marginRight: 16,
    borderRadius: 4,
    overflow: 'hidden',
  },
  image: {
    // width: 57,
    // height: 57,
    objectFit: 'cover',
    borderRadius: 4,
    imageRendering: 'pixelated',
  },
  hiddenImage: {
    opacity: 0,
  },
  selectItem: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '4px 12px 4px 10px',
    borderRadius: '4px',
    cursor: 'pointer',
    height: '67px',
    '&:not(:last-child)': {
      marginBottom: '4px',
    },
    '&[data-selected="true"]': {
      backgroundColor: theme.colors.greyDark[3],
    },
    '&:hover': {
      backgroundColor: theme.colors.greyDark[3],
    },
  },
  selectItem__infoWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  selectDropdown__buttonsWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '12px 17px',
  },
  checkBox: {
    '& input': {
      cursor: 'pointer',
    },
  }
}));
export enum BadgeVariant {
  Success = 'Success',
  Default = 'Default',
}

export interface SelectItemProps {
  onClick: (props: {
    value: string
    checked: boolean,
  }) => void;
  checked?: boolean;
  image: string | null;
  title: string;
  value: string;
  withCheckboxes?: boolean;
  badges?: {
    text: string;
    variant?: BadgeVariant;
  }[];
}


export const SelectItem: FC<SelectItemProps> = React.memo(({onClick, checked, image, title, value, withCheckboxes, badges}) => {
  const {classes} = useStyles();

  const handleCheckboxChange = useCallback(() => {
    onClick({value: value, checked: !checked});
  }, [checked, onClick, value])



const modifiedImage = image ? `${image}&width=114` : undefined;
  return (
    <Box  className={classes.selectItem} onClick={handleCheckboxChange} data-selected={checked}>
      <Box className={classes.selectItem__infoWrapper}>
        <Box className={classes.imageContainer}>
          <AppImage
            loading="lazy"
            src={modifiedImage}
            w={57} h={57}
            className={classes.image}
            key={image}
            />
        </Box>
        <Text color="greyLight.2">{title}</Text>
      </Box>
      {(badges || withCheckboxes) && <Flex align="center" gap="16px">
        {badges && <Box className={styles.badgesContainer}>
          {badges.map((badge) => (
            <Badge
              color={badge.variant === BadgeVariant.Success ? 'green.1' : 'gray'}
              radius="md"
              variant="outline"
              size="xs"
              w={70}
              key={badge.text}
            >{badge.text}</Badge>
          ))}
        </Box>}
        {withCheckboxes && <Checkbox
          className={classes.checkBox}
          checked={checked}
          color="green.1"
          onChange={handleCheckboxChange}
        />}
      </Flex>}
    </Box>
  );
});

interface SelectDropdownButtonsProps {
  onCancel: () => void;
  onConfirm: () => void;
  disableConfirmButton: boolean;
}

export const SelectDropdownButtons: FC<SelectDropdownButtonsProps> = ({
                                                                        onCancel,
                                                                        onConfirm,
                                                                        disableConfirmButton,
                                                                      }) => {
  const {classes} = useStyles();
  return (
    <Box className={classes.selectDropdown__buttonsWrapper}>
      <Button variant="default" mr={12} onClick={onCancel}>
        Cancel
      </Button>
      <Button color="green.1" onClick={onConfirm} disabled={disableConfirmButton}>
        Save
      </Button>
    </Box>
  );
};
